import NavBar from "../components/NavBar/NavBar"
import './blog.css';
import { Link } from 'react-router-dom';
import equinox from "../pages/vid/equinox.mp4";
import { useEffect } from "react";

export const Blog = () => {

    // const debounce = (fn:any, delay:any) => {
    //     // method 2:
    //     return function() {

    //     }
    //     // method 1:
    //     // let timeoutId: any;
    //     // return (...args:any) => {
    //     //     if (timeoutId) clearTimeout(timeoutId);
    //     //     timeoutId = setTimeout(() => {
    //     //         fn(...args)
    //     //     }, delay);
    //     // }
    //     // After debounce/outer function has run and popped off callstack
    //     // inner function will still have access to timeoutId!! (closure)
    // }

    /**
     * 
     * function onScrollFunction(arg) {
  console.log('scrolling ' + arg);
}

function debounce(fn, wait) {
  let timeout;
  
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.call(this, ...args), wait)
  };
}

const debounced = debounce(onScrollFunction, 1000);

window.addEventListener('scroll', () => debounced(1)); 
     */

    // const throttle = (fn: any, delay: any) => {
    //     let last: any = 0;
    //     console.log(last);
    //     return (...args: any) => {
    //         const now = new Date().getTime();
    //         console.log(now, last, '33')
    //         if (now - last < delay) return;
    //         else {
    //             last = now;
    //             return fn(...args);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     document.getElementById('button')!.addEventListener('click', throttle((e: any) => console.log('clicked'), 2000));
    // }, []);

    
    return (
        <>
        {/* <button id="button">Click me</button> */}
        <div className="blogNav"><li><Link to="/">🏠</Link></li></div>
        <div className="blog">
            {/* <NavBar/> */}
            <div className="blogContent">
                <main>
                    {/* <article>
                        <header>
                        <h1>Uber in Canada</h1>
                        <time dateTime="2022">2022</time>
                        </header>
                        <p>
                        </p>
                    </article> */}
                                        <article>
                        <header>
                            <h1>aphorism</h1>
                            <time dateTime="2022">01/2024</time>
                        </header>
                        <p>
                            even the best cocktail pales in comparison to a guiness
                        </p>
                    </article>
                    <article>
                        <header>
                            <h1>hyper competition</h1>
                            <time dateTime="2022">2022</time>
                        </header>
                        <p>
                            The fragmentation of the neoliberal self begins when the agent is brought face to face with the realization that 
                            she is not just an employee or student, but also simultaneously a product to be sold, a walking advertisement, a
                            manager of her résumé, a biographer of her rationales, and an entrepreneur of her possibilities. She has to somehow 
                            manage to be simultaneously subject, object, and spectator. She is perforce not learning about who she really is, 
                            but rather, provisionally buying the person she must soon become. She is all at once the business, the raw material,
                            the product, the clientele, and the customer of her own life. She is a jumble of assets to be invested, nurtured, 
                            managed, and developed; but equally an offsetting inventory of liabilities to be pruned, outsourced, shorted,
                            hedged against, and minimized. She is both headline star and enraptured audience of her own performance.
                            These are not effortless personas to be adopted, but roles to be fortified and regimented on a continuous basis.
                            As Foucault insisted, the neoliberal self dissolves the distinction between producer and consumer. Furthermore,
                            there is no preset hierarchy of resident personas, but only a shifting cast of characters, depending upon 
                            the exigencies of the moment. The summum bonum of modern agency is to present oneself as eminently flexible 
                            in any and all respects.
                        </p>
                        <i>- Philip Mirowski</i>
                    </article>
                    <article>
                        <header>
                            <h1>equinox</h1>
                            <time dateTime="2021">2021</time>
                        </header>
                        <p>
                            <video height="400px" width="800px" controls src={equinox}></video>
                        </p>
                    </article>
                    <article>
                        <header>
                            <h1>epoch</h1>
                            <time dateTime="1970-01-01">jan 01, 1970</time>
                        </header>
                        <p>
                            lorem ipsum dolor
                        </p>
                    </article>

                </main>
            </div>
        </div>
        </>
    )
}