import NavBar from "../components/NavBar/NavBar"
import './links.css';
export const Links = () => {
    return (
        <>
        {/* <NavBar/> */}
        <div className="links">
            {/* <h1 style={{
                position: 'absolute',
                top: '50%',
                color: "white",
            }}>Links</h1> */}
            <ul className="linksList">
                <li> <a href="https://www.themarginalian.org/2014/11/11/dostoyevsky-dream/">Dostoevsky Dream</a></li>
                <li> <a href="https://en.wikipedia.org/wiki/Goodhart%27s_law">Goodhart's Law</a></li>
                <li> <a href="https://aeon.co/essays/gregory-bateson-changed-the-way-we-think-about-changing-ourselves">Gregory Bateson</a></li>
                <li> <a href="https://commoncog.com/blog/cash-flow-games/">Cash Flow</a></li>
                <li> <a href="https://astralcodexten.substack.com/">Astral Codex 10</a></li>
                <li> <a href="https://en.wikipedia.org/wiki/Ghoti">Ghoti</a></li>
                <li> <a href="https://www.kalzumeus.com/2014/11/07/doing-business-in-japan/">Salaryman</a></li>
                <li> <a href="http://www.paulgraham.com/cities.html">Cities</a></li>
                <li> <a href="https://www.newyorker.com/magazine/2007/12/10/the-checklist">Checklists</a></li>
                <li> <a href="https://www.zocalopublicsquare.org/2011/11/30/how-doctors-die/ideas/nexus/">How Doctors Die</a></li>
                <li> <a href="https://mattlakeman.org/2021/07/27/shadow-of-the-sun/">Shadow of the Sun</a></li>
                <li> <a href="https://www.youtube.com/watch?v=eC7xzavzEKY">This is Water</a></li>
                <li> <a href="https://waitbutwhy.com/2014/05/life-weeks.html">Life in Weeks</a></li>
                {/* <li><a href="https://eugyppius.substack.com/p/covid-is-a-social-construct">Eugyppius</a></li>  */}
                <li><a href="https://en.wikipedia.org/wiki/Panopticon">Panopticon</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Overton_window">Overton Window</a></li>
                <li><a href="https://www.issendai.com/psychology/sick-systems.html">Sick Systems</a></li>
                <li><a href="https://dynomight.net/plans/">Plans</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Long_march_through_the_institutions">Long march</a></li>
                <li><a href="https://thelastpsychiatrist.com/2012/12/funeral.html">Funeral</a></li>
                <li><a href="https://sites.google.com/site/forgottenemployee/">Forgotten Employee</a></li>
                <li><a href="https://reallifemag.com/the-magnificent-bribe/">The Magnificent Bribe</a></li>
                <li><a href="https://thelastpsychiatrist.com/2008/10/those_five_days_matter_more_th.html">Those 5 Days</a></li>
                <li><a href="https://robkhenderson.substack.com/p/the-logic-of-envy">The Logic of Envy</a></li>
                <li><a href="https://hotelconcierge.tumblr.com/post/173526578129/shame-society/amp">Shame and Society</a></li>
                <li><a href="https://thelastpsychiatrist.com/2013/05/dove.html">Dove</a></li>
                <li><a href="https://www.bafta.org/media-centre/transcripts/screenwriters-lecture-charlie-kaufman">Kaufman</a></li>
                <li><a href="https://en.wikipedia.org/wiki/D%C3%A9rive">Derive</a></li>
                <li><a href="https://alexdanco.com/2019/04/28/secrets-about-people-a-short-and-dangerous-introduction-to-rene-girard/">Girard</a></li>
                {/* <li><a href="">{}</a></li> */}
            </ul>
        </div>
        </>
    )
}
