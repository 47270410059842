/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Film } from './pages/film';
import { Books } from './pages/books';
import { Links } from './pages/links';
import { Blog } from './pages/blog';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/links" element={<Links />} />
      <Route path="/blog" element={<Blog />} />
    </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
