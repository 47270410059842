import './NavBar.css';
import { NavLink } from 'react-router-dom';

interface NavBarProps {
    extraLinks?: string;
}

export default function NavBar({extraLinks} : NavBarProps): JSX.Element {
    return (
        <div className="navbar">
            <ul>
                {/* <li>
                    <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to="/">Home</NavLink>
                </li> */}
                {/*
                // <li>
                //     <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to='/musings'>Rand</NavLink>
                // </li>
                // <li>
                //     <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to="/flicks">Films</NavLink>
                // </li>
                // <li>
                //     <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to="/reads">Reads</NavLink>
                // </li> */}
                <li>
                    <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to="/links">Links</NavLink>
                </li>
                <li>
                    <NavLink style={({ isActive }) => ({ display: isActive ? 'none' : '' })} to="/blog">Blog</NavLink>
                </li>
            </ul>
        </div>
    )
}