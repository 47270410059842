import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
// import { Tilt } from 'react-vanilla-tilt';
import NavBar from './components/NavBar/NavBar';
import './App.css';
import linkedin from './public/linkedin.png';
import BlobAnimation from './components/BlobAnimation';
function App() {

// const createGame = (context: any) => {
//   // Start the frame count at 1
//   let frameCount = 1;
//   // Set the number of obstacles to match the current "level"
//   let obCount = frameCount;
//   // Create a collection to hold the generated x coordinates
//   const obXCoors: number[] = [];
  
//   const square = {
//     height: 32,
//     jumping: true,
//     width: 32,
//     x: 0,
//     xVelocity: 0,
//     y: 0,
//     yVelocity: 0
  
//   };
  
//   // Create the obstacles for each frame
//   const nextFrame = () => {
//     // increase the frame / "level" count
//     frameCount++;
    
//     for (let i = 0; i < obCount; i++) {
//       // Randomly generate the x coordinate for the top corner start of the triangles
//       let obXCoor = Math.floor(Math.random() * (1165 - 140 + 1) + 140);
//       obXCoors.push(obXCoor);
//     }
  
//   }
  
//   const controller = {
  
//     left: false,
//     right: false,
//     up: false,
//     keyListener: function (event: any) {
  
//       var key_state = (event.type == "keydown") ? true : false;
  
//       switch (event.keyCode) {
  
//         case 37:// left key
//           controller.left = key_state;
//           break;
//         case 38:// up key
//           controller.up = key_state;
//           break;
//         case 39:// right key
//           controller.right = key_state;
//           break;
  
//       }
  
//     }
  
//   };
  
//   const loop = function () {
  
//     if (controller.up && square.jumping == false) {
  
//       square.yVelocity -= 30;
//       square.jumping = true;
  
//     }
  
//     if (controller.left) {
  
//       square.xVelocity -= 0.5;
  
//     }
  
//     if (controller.right) {
  
//       square.xVelocity += 0.5;
  
//     }
  
//     square.yVelocity += 1.5;// gravity
//     square.x += square.xVelocity;
//     square.y += square.yVelocity;
//     square.xVelocity *= 0.9;// friction
//     square.yVelocity *= 0.9;// friction
  
//     // if square is falling below floor line
//     if (square.y > 200 - 32) {
  
//       square.jumping = false;
//       square.y = 200 - 32;
//       square.yVelocity = 0;
  
//     }
  
//     // if square is going off the left of the screen
//     if (square.x < -20) {
  
//       square.x = 1440;
  
//     } else if (square.x > 1440) {// if square goes past right boundary
  
//       square.x = -20;
//       nextFrame();
  
//     }
//     // Creates the backdrop for each frame
//     context.fillStyle = "black";
//     context.fillRect(0, 0, 1440, 200); // x, y, width, height
  
  
//     // Creates and fills the cube for each frame
//     context.fillStyle = "white"; // hex for cube color
//     context.beginPath();
//     context.rect(square.x, square.y, square.width, square.height);
//     context.fill();
  
  
//     // Create the obstacles for each frame
//     // Set the standard obstacle height
//     const height = 50 * Math.cos(Math.PI / 6);
  
//     context.fillStyle = "white"; // hex for triangle color
//     obXCoors.forEach((obXCoor) => {
//       context.beginPath();
  
//       context.moveTo(obXCoor, 200); // x = random, y = coor. on "ground"
//       context.lineTo(obXCoor + 20, 200); // x = ^random + 20, y = coor. on "ground"
//       context.lineTo(obXCoor + 10, 210 - height); // x = ^random + 10, y = peak of triangle
    
//       context.closePath();
//       context.fill();
//     })
  
  
//     // Creates the "ground" for each frame
//     // context.strokeStyle = "#2E2532";
//     // context.lineWidth = 30;
//     // context.beginPath();
//     // context.moveTo(0, 185);
//     // context.lineTo(1440, 185);
//     // context.stroke();
  
//     // call update when the browser is ready to draw again
//     window.requestAnimationFrame(loop);
  
//   };
  
//   window.addEventListener("keydown", controller.keyListener)
//   window.addEventListener("keyup", controller.keyListener);
//   window.requestAnimationFrame(loop);
// }

  useEffect(() => {
  // const context = document!.querySelector('canvas')!.getContext!('2d');
  // context!.canvas.height = 300;
  // context!.canvas.width = 1440;
  // createGame(context!);
  }, [])

  return (
    <>
    <NavBar/>
    <div className="container">
      <div>
        <span className={"removeOnSmallScreen"}><BlobAnimation/></span>
        <h1 
        data-title={'Kirtan Purohit'}          
        className="textFillName name">
          Kirtan Purohit
          </h1>
        </div>
        <div className="break"/>
        {/* <div className="jobDescription">
          Software Engineer @ <span data-title-company={'Amazon'} className="textfillCompany">Amazon</span>
        </div> */}
        <div className="break"/>
        <div className="email">
            kirtan (at) purohit (dot) me
        </div>
        <div className="break"/>
        <div className="ccc">
          <a 
          href="https://www.linkedin.com/in/kirtanpurohit/" 
          target="_blank" 
          rel="noopener"
          >
          <img alt="LinkedIn" src={linkedin} width="55px"></img>
          </a>
        </div>
        <div className="break"/>
      </div>
      {/* <Tilt>Hello</Tilt> */}
      {/* <canvas style={{ 
        position: 'absolute',
        bottom: '0px',

        }}>

      </canvas> */}
    </>
  );
}

export default App;
