import styled, { keyframes } from 'styled-components';
const BlobAnimation = () => {
    return (
        <div>
            <Blob />
            <Blob2 />
        </div>
    )
}

const move = keyframes`
from {
    transform: translate(-200px, -150px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
}
to {
    transform: translate(300px, 0px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;

}
`;

const move2 = keyframes`
from {
    transform: translate(-350px, -300px) rotate(-90deg);
    border-radius: 51% 49% 58% 42% / 34% 78% 22% 66%;
}
to {
    transform: translate(400px, 50px) rotate(-120deg);
    border-radius: 22% 78% 73% 27% / 68% 31% 69% 32%;

}
`;

const Blob = styled.div`
    position: absolute;
    width: 500px;
    height: 390px;
    background: linear-gradient(180deg, rgba(47, 184, 255, 0.42) 31.77%, #9eecd9 100%);
    mix-blend-mode: color-dodge;
    transition: 1s cubic-bezier(0.08, 0.8, 0.16, 1);
    animation: ${move} 20s alternate infinite;
    z-index: -1;
    :hover {
        width: 520px;
        height: 410px;
        filter: blur(30px);
    }
    `;

const Blob2 = styled(Blob)`
    background: linear-gradient(180deg, rgba(186, 184, 255, 0.42) 31.77%, #9eecd9 100%);
    animation: ${move2} 30s alternate infinite;
`;
export default BlobAnimation;